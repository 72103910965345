<template>
    <div class="btn-group">
        <template v-for="option in options" :key="option[option_value]">
            <label
                class="btn radio-label"
                :class="{
                    'btn-outline-secondary': (String(checked_option) !== String(option[option_value])), 'btn-secondary': (String(checked_option) === String(option[option_value])),
                    'disabled': (disabled_keys && disabled_keys.length > 0 && disabled_keys.includes(Number(option[option_value]))),
                }"
            >
                <input
                    type="radio"
                    :name="input_name"
                    :value="option[option_value]"
                    v-model="checked_option"
                    @change="$emit('update:modelValue', this.checked_option); $emit('clicked_change');"
                    :required=required
                    :disabled="(disabled_keys && disabled_keys.length > 0 && disabled_keys.includes(Number(option[option_value]))) ? 'disabled' : false"
                    class="btn-check radio-input"
                    autocomplete="off"
                >
                {{ option[option_label] }}
            </label>
        </template>
    </div>
</template>

<script>
import { getCurrentInstance } from 'vue';
export default {
    name: 'FormRadio',
    props: {
        modelValue: {
        },
        options: {
            type: Array,
        },
        option_value: {
            type: String,
            default: 'value'
        },
        option_label: {
            type: String,
            default: 'label'
        },
        required: {
            type: Boolean,
            default: false
        },
        //disabledするkeyの配列を渡す
        disabled_keys: {
            type: Array,
            default: () => {},
        },
    },
    emits: [
        'update:modelValue',
        'clicked_change',
    ],
    data() {
        return {
            checked_option: this.modelValue,
        }
    },
    setup() {
        //inputにユニークなname値を自動で採番するためuidを利用（name値がないとform validationできないため）
        let input_name = getCurrentInstance().uid;
        return {
            input_name,
        }
    },
    watch: {
        modelValue() {
            this.checked_option = this.modelValue;
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
