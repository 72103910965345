<template>

    <!-- 編集 -->
    <section v-if="is_edit" class="section pb-5">
        <div class="section-title">
            <h2>顧客情報</h2>
            <div>
                <button @click="is_edit = false; changeIsEdit(false);" type="button" class="btn btn-outline-secondary me-3"><i class="bi bi-x"></i> キャンセル</button>
                <button form="update_customer_form" type="submit" class="btn btn-info"><i class="bi bi-save"></i> 保存</button>
            </div>
        </div>
        <h3 class="mb-2 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> 基本情報</h3>
        <form @submit.prevent="save" id="update_customer_form">
            <div class="row mb-3">
                <div class="col-9">
                    <label class="form-label">会社名 <i class="required"></i></label>
                    <form-input v-model="edit_project.customer.company_name" :required='true'></form-input>
                </div>
                <div class="col-9">
                    <label class="form-label">代表者名</label>
                    <form-input v-model="edit_project.customer.representative_name"></form-input>
                </div>
                <div class="col-18">
                    <label class="form-label">本社住所</label>
                    <form-input v-model="edit_project.customer.address"></form-input>
                </div>
            </div>
            <div class="row mb-3 align-items-end">
                <div class="col-9">
                    <label class="form-label">業種</label>
                    <form-select-search
                        v-model="edit_project.customer.industry"
                        :options="Industry.options()"
                        empty_option="----"
                    ></form-select-search>
                </div>
                <div class="col-9">
                    <form-checkbox
                        v-model="edit_project.customer.is_major"
                        option_label="大手企業"
                    ></form-checkbox>
                </div>
                <div class="col-9">
                    <label class="form-label">会社規模</label>
                    <form-select
                        v-model="edit_project.customer.company_scale"
                        :options="CompanyScale.options()"
                        empty_option="----"
                    ></form-select>
                </div>
                <div class="col-9">
                    <label class="form-label">従業員数</label>
                    <div class="input-group">
                        <form-input-number
                            v-model="edit_project.customer.employee_number"
                            min="0"
                            max="99999999"
                        ></form-input-number>
                        <span class="input-group-text">名</span>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-9">
                    <label class="form-label">電話</label>
                    <form-input v-model="edit_project.customer.phone"></form-input>
                </div>
                <div class="col-9">
                    <label class="form-label">Fax</label>
                    <form-input v-model="edit_project.customer.fax"></form-input>
                </div>
                <div class="col-5">
                    <label class="form-label">決算期</label>
                    <div class="input-group">
                        <form-input v-model="edit_project.customer.fiscal_month"></form-input>
                        <span class="input-group-text">月</span>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-18">
                    <label class="form-label">Webサイト</label>
                    <form-input v-model="edit_project.customer.website"></form-input>
                </div>
            </div>

            <h3 class="mb-2 mt-5"><i class="bi bi-caret-right-fill text-primary"></i> 取引情報</h3>
            <div class="row mb-3">
                <div class="col-18">
                    <label class="form-label">社長名で検索</label>
                    <div class="input-group">
                        <form-select
                            v-model="edit_project.customer.check_representative_name"
                            :options="IsProblem.options()"
                            empty_option="----"
                        ></form-select>
                        <form-input v-model="edit_project.customer.check_representative_name_note"></form-input>
                    </div>
                </div>
                <div class="col-18">
                    <label class="form-label">「社名+KW」で検索</label>
                    <div class="input-group">
                        <form-select
                            v-model="edit_project.customer.check_keyword_company_name"
                            :options="IsProblem.options()"
                            empty_option="----"
                        ></form-select>
                        <form-input v-model="edit_project.customer.check_keyword_company_name_note"></form-input>
                    </div>
                </div>
            </div>
            <div class="row mb-3 align-items-end">
                <div class="col-18">
                    <label class="form-label">「代表者名+KW」で検索</label>
                    <div class="input-group">
                        <form-select
                            v-model="edit_project.customer.check_keyword_representative_name"
                            :options="IsProblem.options()"
                            empty_option="----"
                        ></form-select>
                        <form-input v-model="edit_project.customer.check_keyword_representative_name_note"></form-input>
                    </div>
                </div>
                <div class="col-18">
                    <label class="form-label">「本社住所+KW」で検索</label>
                    <div class="input-group">
                        <form-select
                            v-model="edit_project.customer.check_keyword_address"
                            :options="IsProblem.options()"
                            empty_option="----"
                        ></form-select>
                        <form-input v-model="edit_project.customer.check_keyword_address_note"></form-input>
                    </div>
                </div>
            </div>
            <div class="row mb-3 align-items-end">
                <div class="col-9">
                    <form-checkbox
                        v-model="edit_project.customer.is_tradable"
                        option_label="取引可"
                    ></form-checkbox>
                </div>
                <div class="col-9">
                    <form-checkbox
                        v-model="edit_project.customer.can_electronic_contract"
                        option_label="電子契約可"
                    ></form-checkbox>
                </div>
                <div class="col-9">
                    <form-checkbox
                        v-model="edit_project.customer.is_delivery_stop"
                        option_label="配信停止"
                    ></form-checkbox>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-36">
                    <label class="form-label">備考</label>
                    <form-textarea
                        v-model="edit_project.customer.note"
                    ></form-textarea>
                </div>
            </div>
        </form>
    </section>

    <!-- 閲覧 -->
    <section v-else class="section pb-5">
        <div class="section-title">
            <h2>顧客情報</h2>
            <button v-if="$store.getters['auth/canEditProject']" type="button" @click="is_edit = true; changeIsEdit(true);" class="btn btn-outline-primary" :disabled="is_editing_header"><i class="bi bi-pencil-square"></i> 編集</button>
        </div>
        <h3 class="mb-2 mt-5">
            <i class="bi bi-caret-up-fill text-primary" :class="{'toggle-close': is_open.basic === false, 'toggle-open': is_open.basic === true}"></i>
            <span @click="is_open.basic = !is_open.basic" role="button">基本情報</span>
        </h3>
        <transition>
            <div v-if="is_open.basic">
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">会社名</label>
                        <div class="form-readonly">{{ project.customer.company_name }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">代表者名</label>
                        <div class="form-readonly">{{ project.customer.representative_name }}</div>
                    </div>
                    <div class="col-18">
                        <label class="form-label">本社住所</label>
                        <div class="form-readonly">{{ project.customer.address }}</div>
                    </div>
                </div>
                <div class="row mb-3 align-items-end">
                    <div class="col-9">
                        <label class="form-label">業種</label>
                        <div class="form-readonly">{{ project.customer.industry_label }}</div>
                    </div>
                    <div class="col-9">
                        <div class="form-readonly">
                            <i v-if="project.customer.is_major === IsMajor.YES" class="bi bi-check-square"></i>
                            <i v-else class="bi bi-dash-square"></i>
                            大手企業
                        </div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">会社規模</label>
                        <div class="form-readonly">{{ project.customer.company_scale_label }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">従業員数</label>
                        <div class="form-readonly text-end">
                            <template v-if="project.customer.employee_number">
                                {{ $helper.number(project.customer.employee_number) }} 名
                            </template>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-9">
                        <label class="form-label">電話</label>
                        <div class="form-readonly">{{ project.customer.phone }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">Fax</label>
                        <div class="form-readonly">{{ project.customer.fax }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">決算期</label>
                        <div class="form-readonly">
                            <template v-if="project.customer.fiscal_month">
                                {{ project.customer.fiscal_month }} 月
                            </template>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-36">
                        <label class="form-label">Webサイト</label>
                        <div class="form-readonly-ellipsis"><a :href="project.customer.website" target="_blank">{{ project.customer.website }}</a></div>
                    </div>
                </div>
            </div>
        </transition>

        <h3 class="mb-2 mt-5">
            <i class="bi bi-caret-up-fill text-primary" :class="{'toggle-close': is_open.trade === false, 'toggle-open': is_open.trade === true,}"></i>
            <span @click="is_open.trade = !is_open.trade" role="button">取引情報</span>
        </h3>
        <transition>
            <div v-if="is_open.trade">
                <div class="row mb-3">
                    <div class="col-18">
                        <label class="form-label">社長名で検索</label>
                        <div class="form-readonly"><span class="d-inline-block me-4">{{ IsProblem.get(project.customer.check_representative_name) }}</span>{{ project.customer.check_representative_name_note }}</div>
                    </div>
                    <div class="col-18">
                        <label class="form-label">「社名+KW」で検索</label>
                        <div class="form-readonly"><span class="d-inline-block me-4">{{ IsProblem.get(project.customer.check_keyword_company_name) }}</span>{{ project.customer.check_keyword_company_name_note }}</div>
                    </div>
                </div>
                <div class="row mb-3 align-items-end">
                    <div class="col-18">
                        <label class="form-label">「代表者名+KW」で検索</label>
                        <div class="form-readonly"><span class="d-inline-block me-4">{{ IsProblem.get(project.customer.check_keyword_representative_name) }}</span> {{ project.customer.check_keyword_representative_name_note }}</div>
                    </div>
                    <div class="col-18">
                        <label class="form-label">「本社住所+KW」で検索</label>
                        <div class="form-readonly"><span class="d-inline-block me-4">{{ IsProblem.get(project.customer.check_keyword_address) }}</span> {{ project.customer.check_keyword_address_note }}</div>
                    </div>
                </div>
                <div class="row mb-3 align-items-end">
                    <div class="col-9">
                        <div class="form-readonly">
                                <i v-if="project.customer.is_tradable === IsTradable.YES" class="bi bi-check-square"></i>
                                <i v-else class="bi bi-dash-square"></i>
                            取引可
                        </div>
                    </div>
                    <div class="col-9">
                        <div class="form-readonly">
                            <i v-if="project.customer.can_electronic_contract === CanElectronicContract.YES" class="bi bi-check-square"></i>
                            <i v-else class="bi bi-dash-square"></i>
                            電子契約可
                        </div>
                    </div>
                    <div class="col-9">
                        <div class="form-readonly">
                            <i v-if="project.customer.is_delivery_stop === IsDeliveryStop.YES" class="bi bi-check-square"></i>
                            <i v-else class="bi bi-dash-square"></i>
                            配信停止
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-36">
                        <label class="form-label">備考</label>
                        <div class="form-readonly">{{ project.customer.note }}</div>
                    </div>
                </div>
            </div>
        </transition>
    </section>

</template>

<script>
import Project from '@/models/entities/project';
import Customer from '@/models/entities/customer';
import Industry from '@/models/enums/industry';
import IsProblem from '@/models/enums/is-problem';
import IsTradable from '@/models/enums/is-tradable';
import IsDeliveryStop from '@/models/enums/is-delivery-stop';
import CompanyScale from '@/models/enums/company-scale';
import CanElectronicContract from '@/models/enums/can-electronic-contract';
import IsMajor from '@/models/enums/is-major';
import FormInput from '@/components/forms/FormInput';
import FormSelect from '@/components/forms/FormSelect';
import FormSelectSearch from '@/components/forms/FormSelectSearch';
import FormInputNumber from '@/components/forms/FormInputNumber';
import FormCheckbox from '@/components/forms/FormCheckbox';
import FormTextarea from '@/components/forms/FormTextarea';

export default {
    name: 'ProjectCustomer',
    components: {
        FormInput,
        FormSelect,
        FormSelectSearch,
        FormInputNumber,
        FormCheckbox,
        FormTextarea,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    props: {
        project: {
            type: Object,
            default: new Project(),
        },
        // ヘッダー編集中フラグ
        is_editing_header: {
            type: Boolean,
        }
    },
    emits: [
        'update',
        'changeIsEditCustomer',
    ],
    data() {
        return {
            // 編集・閲覧切り替え
            is_edit: false,

            // enum類
            Industry,
            IsProblem,
            IsTradable,
            CompanyScale,
            CanElectronicContract,
            IsMajor,
            IsDeliveryStop,

            // 編集用
            edit_project: new Project(JSON.parse(JSON.stringify(this.project))),

            // アコーディオン開閉フラグ
            is_open: {
                basic: false, // 基本情報
                trade: false, // 取引情報
            },
        }
    },
    // 親コンポーネントprojectの変更を検知
    watch: {
        project(new_project) {
            this.edit_project = new_project;
        },
    },
    methods: {
        // 保存処理
        save() {
            this.startScreenLoading();

            this.$http.put(`/project/${this.edit_project.project_id}/customer/${this.edit_project.customer.customer_id}`, this.edit_project.customer)
            .then(response => {
                this.is_edit = false;
                this.changeIsEdit(false);

                this.showMessage('顧客情報を保存しました');
                this.edit_project.customer = new Customer(response.data);
                this.$emit('update', this.edit_project);
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 親に渡して案件情報と顧客情報の編集を制御
        changeIsEdit(bool) {
            this.$emit('changeIsEditCustomer', bool);
        },
    },
}
</script>

<style scoped>
    .row { align-items: stretch; }
    .section div.row>* {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .status-bar li:hover {
        cursor: pointer;
    }
    .selected {
        background-color: var(--bs-gray-400);
    }

    .selected + li:before {
        background-color: var(--bs-gray-400);
    }
    .status-bar li.selected:last-child:after {
        background-color: var(--bs-gray-400);
    }
</style>