<template>
    <section class="section bg-light">

        <div class="row align-items-center mb-1">
            <div class="col-34">
                <div class="status-bar scroll_container" ref="scroll_container">
                    <!-- ステータス -->
                    <ul>
                        <template v-for="status in Status.options()" :key="status.value">
                            <li @click="selectStatus(status)"
                                :class="{
                                    'active': parseInt(status.value, 10) === project.status,
                                    'selected': isSelected(status)
                                }"
                            >
                                {{ status.label }}
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
            <div class="col-2 text-end">
                <button v-if="$store.getters['auth/canEditProject']" type="button" @click="update()" class="btn btn-info px-4 py-3">更新</button>
            </div>
        </div>

        <!-- 編集 -->
        <section v-if="is_edit">
            <div class="section-title bg-light justify-content-end my-3">
                <button @click="is_edit = false; changeIsEdit(false);" type="button" class="btn btn-outline-secondary me-3"><i class="bi bi-x"></i> キャンセル</button>
                <button form="update_project_form" type="submit" class="btn btn-info"><i class="bi bi-save"></i> 保存</button>
            </div>

            <form @submit.prevent="save()" id="update_project_form">
                <div class="row mb-3">
                    <div class="col-6">
                        <label class="form-label">会社名 <i class="required"></i></label>
                        <form-input v-model="edit_project.customer.company_name" :required='true'></form-input>
                    </div>
                    <div class="col-6">
                        <label class="form-label">主担当者 <i class="required"></i></label>
                        <form-input v-model="edit_primary_user.contact_name" :required="true"></form-input>
                    </div>
                    <div class="col-6">
                        <label class="form-label">主担当者 (かな)</label>
                        <form-input v-model="edit_primary_user.contact_name_kana"></form-input>
                    </div>
                    <div class="col-6">
                        <label class="form-label">役職</label>
                        <div class="input-group">
                            <form-checkbox v-model="edit_primary_user.is_ceo" option_label="代表" style="white-space: nowrap;"></form-checkbox>
                            <form-input v-model="edit_primary_user.position" style="flex-grow: 2;"></form-input>
                        </div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">携帯電話</label>
                        <form-input v-model="edit_primary_user.phone"></form-input>
                    </div>
                    <div class="col-6">
                        <label class="form-label">メールアドレス</label>
                        <form-input v-model="edit_primary_user.email"></form-input>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-6">
                        <label class="form-label">会社電話番号</label>
                        <form-input v-model="edit_project.customer.phone"></form-input>
                    </div>
                    <div class="col-6">
                        <label class="form-label">Webサイト</label>
                        <form-input v-model="edit_project.customer.website"></form-input>
                    </div>
                    <div class="col-6">
                        <label class="form-label">従業員数</label>
                        <div class="input-group">
                            <form-checkbox
                                v-model="edit_project.customer.is_major"
                                option_label="大手"
                                class="nowrap pe-3"
                            ></form-checkbox>
                            <form-input-number
                                v-model="edit_project.customer.employee_number"
                                min="0"
                                max="99999999"
                                class="flex-2"
                            ></form-input-number>
                            <span class="input-group-text">名</span>
                        </div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">規格</label>
                        <form-select-search-multiple
                            v-model="edit_project.standards_values"
                            :options="Standard.options()"
                        ></form-select-search-multiple>
                    </div>
                    <div class="col-6">
                        <label class="form-label">その他規格</label>
                        <form-input v-model="edit_project.standard_note"></form-input>
                    </div>
                    <div class="col-6">
                        <label class="form-label">確実度</label>
                        <form-select-object
                            v-model="edit_project.certainty"
                            :options="certainties"
                            option_value="certainty_id"
                            option_label="certainty_name"
                            empty_option="----"
                        ></form-select-object>
                    </div>
                </div>

                <!-- 現在のステータスによって切り替え -->
                <!-- アポリスト、反響未対応、対応済み、電話にでんわ、メール送付、資料送付、セミナー -->
                <template v-if="Status.stageAction().includes(selected_status)">
                    <div class="row">
                        <div class="col-6">
                            <label class="form-label">事業部</label>
                            <form-select-object
                                v-model="edit_project.department"
                                :options="options_department"
                                option_value="department_id"
                                option_label="department_name"
                                @selected="changeDepartment()"
                            ></form-select-object>
                        </div>
                        <div class="col-6">
                            <label class="form-label">追客担当</label>
                            <form-select-search
                                v-model="edit_project.follow_user.user_id"
                                :options="options_user"
                                placeholder="----"
                            ></form-select-search>
                        </div>
                        <div class="col-6">
                            <label class="form-label">次回アプローチ日</label>
                            <form-input-date v-model="edit_project.next_approaching_date"></form-input-date>
                        </div>
                        <div class="col-6">
                            <label class="form-label">キャンペーンNo</label>
                            <form-input v-model="edit_project.campaign_number"></form-input>
                        </div>
                    </div>
                </template>

                <!-- アポ調整中、アポ、リスケ -->
                <template v-else-if="Status.stageSchedule().includes(selected_status)">
                    <div class="row">
                        <div class="col-6">
                            <label class="form-label">事業部</label>
                            <form-select-object
                                v-model="edit_project.department"
                                :options="options_department"
                                option_value="department_id"
                                option_label="department_name"
                                @selected="changeDepartment()"
                            ></form-select-object>
                        </div>
                        <div class="col-6">
                            <label class="form-label">営業担当</label>
                            <form-select-search
                                v-model="edit_project.sales_user.user_id"
                                :options="options_user"
                                placeholder="----"
                            ></form-select-search>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <label class="form-label">初回アポイント日時</label>
                            <form-input-datetime v-model="edit_project.first_appointment_datetime"></form-input-datetime>
                        </div>
                        <div class="col-12">
                            <label class="form-label">最新アポイント日時</label>
                            <form-input-datetime v-model="edit_project.latest_appointment_datetime"></form-input-datetime>
                        </div>
                        <div class="col-12" v-if="Status.RESCHEDULING === selected_status">
                            <label class="form-label">次回アプローチ日</label>
                            <form-input-date v-model="edit_project.next_approaching_date"></form-input-date>
                        </div>
                        <div class="col-36 mt-2">
                            <label class="form-label">アポイント備考</label>
                            <form-textarea v-model="edit_project.appointment_note"></form-textarea>
                        </div>
                    </div>
                </template>

                <!-- 翌日、追客、再訪問 -->
                <template v-else-if="Status.stageNext().includes(selected_status)">
                    <div class="row mb-3">
                        <div class="col-6">
                            <label class="form-label">事業部</label>
                            <form-select-object
                                v-model="edit_project.department"
                                :options="options_department"
                                option_value="department_id"
                                option_label="department_name"
                                @selected="changeDepartment()"
                            ></form-select-object>
                        </div>
                        <div class="col-6">
                            <label class="form-label">追客担当</label>
                            <form-select-search
                                v-model="edit_project.follow_user.user_id"
                                :options="options_user"
                                placeholder="----"
                            ></form-select-search>
                        </div>
                        <div class="col-6">
                            <label class="form-label">次回アプローチ日</label>
                            <form-input-date v-model="edit_project.next_approaching_date"></form-input-date>
                        </div>
                        <div class="col-6">
                            <label class="form-label">評価</label>
                            <form-select
                                v-model="edit_project.rank"
                                :options="Rank.options()"
                                empty_option="----"
                            ></form-select>
                        </div>
                        <div class="col-6">
                            <label class="form-label">見込日</label>
                            <form-input-date v-model="edit_project.expected_order_date"></form-input-date>
                        </div>
                        <div class="col-6">
                            <label class="form-label">提案金額</label>
                            <div class="input-group">
                                <form-input-number v-model="edit_project.proposal_price"></form-input-number>
                                <span class="input-group-text">万円</span>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-36">
                            <label class="form-label">追客計画</label>
                            <form-textarea v-model="edit_project.follow_plan"></form-textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-36">
                            <label class="form-label">注意事項</label>
                            <form-textarea v-model="edit_project.sales_note"></form-textarea>
                        </div>
                    </div>
                </template>

                <!-- 他社落ち -->
                <template v-else-if="selected_status === Status.OTHER_COMPANY">
                    <div class="row">
                        <div class="col-6" style="justify-content: flex-start">
                            <label class="form-label">失注日</label>
                            <form-input-date
                                v-model="edit_project.lost_date"
                            ></form-input-date>
                            <div class="mt-3">
                                <form-checkbox v-model="edit_project.no_follow" option_label="追客なし"></form-checkbox>
                            </div>
                        </div>
                        <div class="col-6" style="justify-content: flex-start">
                            <label class="form-label">失注先</label>
                            <form-select-object-search
                                v-model="edit_project.lost_against_competitor"
                                :options="options_competitor"
                                option_value="competitor_id"
                                option_label="competitor_name"
                            ></form-select-object-search>
                        </div>
                        <div class="col-6" style="justify-content: flex-start">
                            <label class="form-label">他社落ち理由</label>
                            <form-select
                                v-model="edit_project.lost_reason"
                                :options="LostReason.options()"
                                empty_option="----"
                            ></form-select>
                        </div>
                        <div class="col-18">
                            <label class="form-label">失注理由</label>
                            <form-textarea v-model="edit_project.lost_cause"></form-textarea>
                        </div>
                    </div>
                </template>

                <!-- 自社運用、取得見送り -->
                <template v-else-if="Status.stageFailure().includes(selected_status)">
                    <div class="row">
                        <div class="col-6" style="justify-content: flex-start">
                            <label class="form-label">失注日</label>
                            <form-input-date
                                v-model="edit_project.lost_date"
                            ></form-input-date>
                            <div class="mt-3">
                                <form-checkbox v-model="edit_project.no_follow" option_label="追客なし"></form-checkbox>
                            </div>
                        </div>
                        <div class="col-30">
                            <label class="form-label">失注理由</label>
                            <form-textarea v-model="edit_project.lost_cause"></form-textarea>
                        </div>
                    </div>
                </template>

                <!-- 受注 -->
                <template v-else-if="selected_status === Status.STARTED">
                    <div class="row align-items-start mb-3">
                        <div class="col-6">
                            <label class="form-label">受注日</label>
                            <form-input-date
                                v-model="edit_project.ordered_date"
                            ></form-input-date>
                            <label class="form-label mt-3">顧客番号</label>
                            <form-input
                                v-model="edit_project.client_number"
                            ></form-input>
                        </div>
                        <div class="col-6">
                            <label class="form-label">受注理由</label>
                            <form-select
                                v-model="edit_project.ordered_reason"
                                :options="OrderedReason.options()"
                                empty_option="----"
                            ></form-select>
                            <label class="form-label mt-3">契約金額</label>
                            <div class="input-group">
                                <form-input-number
                                    v-model="edit_project.contract_price"
                                ></form-input-number>
                                <span class="input-group-text">円</span>
                            </div>
                        </div>
                        <div class="col-24">
                            <label class="form-label">受注理由詳細</label>
                            <form-textarea v-model="edit_project.ordered_reason_detail"></form-textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <label class="form-label">契約ステータス</label>
                            <form-select
                                v-model="edit_project.contract_status"
                                :options="ContractStatus.options()"
                            ></form-select>
                        </div>
                        <div class="col-6">
                            <label class="form-label">契約書原案送付日</label>
                            <form-input-date v-model="edit_project.contract_request_review_date"></form-input-date>
                        </div>
                        <div class="col-6">
                            <label class="form-label">クラウドサイン送付日</label>
                            <form-input-date v-model="edit_project.contract_request_sign_date"></form-input-date>
                        </div>
                        <div class="col-6">
                            <label class="form-label">契約締結完了日</label>
                            <form-input-date v-model="edit_project.contract_execute_date"></form-input-date>
                        </div>
                        <div class="col-6">
                            <label class="form-label">次回アプローチ日</label>
                            <form-input-date v-model="edit_project.next_approaching_date"></form-input-date>
                        </div>
                    </div>
                </template>

                <!-- 追客なし 表示なし -->
                <template v-else>
                </template>
            </form>
        </section>



        <!-- 閲覧 -->
        <section v-else>
            <div class="d-flex justify-content-between align-items-center my-3">
                <div>
                    <span v-if="project.no_follow" class="badge bg-danger">追客なし</span>
                </div>
                <div>
                    <button v-if="$store.getters['auth/canEditProject']" type="button" @click="switchEditMode()" class="btn btn-outline-primary" :disabled="is_editing_detail || is_editing_customer"><i class="bi bi-pencil-square"></i> 編集</button>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-6">
                    <label class="form-label">会社名</label>
                    <div class="form-readonly-ellipsis">{{ project.customer.company_name }}</div>
                </div>
                <div class="col-6">
                    <label class="form-label">主担当者</label>
                    <div class="form-readonly-ellipsis">{{ primary_user.contact_name }}</div>
                </div>
                <div class="col-6">
                    <label class="form-label">主担当者 (かな)</label>
                    <div class="form-readonly-ellipsis">{{ primary_user.contact_name_kana }}</div>
                </div>
                <div class="col-6">
                    <label class="form-label">役職</label>
                    <div class="form-readonly">
                        <i class="bi bi-star" v-if="primary_user.is_ceo"></i>
                        {{ primary_user.position }}
                    </div>
                </div>
                <div class="col-6">
                    <label class="form-label">携帯電話</label>
                    <div class="form-readonly-ellipsis">{{ primary_user.phone }}</div>
                </div>
                <div class="col-6">
                    <label class="form-label">メールアドレス</label>
                    <div class="form-readonly-ellipsis">{{ primary_user.email }}</div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-6">
                    <label class="form-label">会社電話番号</label>
                    <div class="form-readonly">{{ project.customer.phone }}</div>
                </div>
                <div class="col-6">
                    <label class="form-label">Webサイト</label>
                    <div class="form-readonly-ellipsis"><a :href="project.customer.website" target="_blank">{{ project.customer.website }}</a></div>
                </div>
                <div class="col-6">
                    <label class="form-label">従業員数</label>
                    <div class="d-flex">
                        <div class="form-readonly-ellipsis nowrap w-auto">
                            <i v-if="project.customer.is_major === IsMajor.YES" class="bi bi-check-square"></i>
                            <i v-else class="bi bi-dash-square"></i>
                            大手
                        </div>
                        <div class="form-readonly-ellipsis text-end nowrap w-auto flex-1">
                            {{ $helper.number(project.customer.employee_number) }} 名
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <label class="form-label">規格</label>
                    <div class="form-readonly-ellipsis">{{ project.standards_label }}</div>
                </div>
                <div class="col-6">
                    <label class="form-label">その他規格</label>
                    <div class="form-readonly-ellipsis">{{ project.standard_note }}</div>
                </div>
                <div class="col-6">
                    <label class="form-label">確実度</label>
                    <div class="form-readonly-ellipsis">{{ project.certainty?.certainty_name }}</div>
                </div>
            </div>

            <!-- 現在のステータスによって切り替え -->
            <!-- アポリスト、反響未対応、対応済み、電話にでんわ、メール送付、資料送付、セミナー -->
            <template v-if="Status.stageAction().includes(selected_status)">
                <div class="row">
                    <div class="col-6">
                        <label class="form-label">事業部</label>
                        <div class="form-readonly">{{ project.department?.department_name }}</div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">追客担当</label>
                        <div class="form-readonly">{{ project.follow_user?.user_name }}</div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">次回アプローチ日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.next_approaching_date) }}</div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">キャンペーンNo</label>
                        <div class="form-readonly">{{ project.campaign_number }}</div>
                    </div>
                </div>
            </template>

            <!-- アポ調整中、アポ、リスケ -->
            <template v-else-if="Status.stageSchedule().includes(selected_status)">
                <div class="row mt-2">
                    <div class="col-6">
                        <label class="form-label">事業部</label>
                        <div class="form-readonly">{{ project.department?.department_name }}</div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">営業担当</label>
                        <div class="form-readonly">{{ project.sales_user?.user_name }}</div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">初回アポイント日時</label>
                        <div class="form-readonly">{{ $helper.ymdhi(project.first_appointment_datetime) }}</div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">最新アポイント日時</label>
                        <div class="form-readonly">{{ $helper.ymdhi(project.latest_appointment_datetime) }}</div>
                    </div>
                    <div class="col-6" v-if="Status.RESCHEDULING === selected_status">
                        <label class="form-label">次回アプローチ日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.next_approaching_date) }}</div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-36">
                        <label class="form-label">アポイント備考</label>
                        <div class="form-readonly prewrap">{{ project.appointment_note }}</div>
                    </div>
                </div>
            </template>

            <!-- 翌日、追客、再訪問 -->
            <template v-else-if="Status.stageNext().includes(selected_status)">
                <div class="row mb-3">
                    <div class="col-6">
                        <label class="form-label">事業部</label>
                        <div class="form-readonly">{{ project.department?.department_name }}</div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">追客担当</label>
                        <div class="form-readonly">{{ project.follow_user?.user_name }}</div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">次回アプローチ日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.next_approaching_date) }}</div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">評価</label>
                        <div class="form-readonly">{{ project.rank_label }}</div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">見込日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.expected_order_date) }}</div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">提案金額</label>
                        <div class="form-readonly text-end">{{ $helper.number(project.proposal_price) }} 万円</div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-36">
                        <label class="form-label">追客計画</label>
                        <div class="form-readonly prewrap">{{ project.follow_plan }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-36">
                        <label class="form-label">注意事項</label>
                        <div class="form-readonly prewrap">{{ project.sales_note }}</div>
                    </div>
                </div>
            </template>

            <!-- 他社落ち -->
            <template v-else-if="selected_status === Status.OTHER_COMPANY">
                <div class="row">
                    <div class="col-6">
                        <label class="form-label">失注日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.lost_date) }}</div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">失注先</label>
                        <div class="form-readonly">{{ project.lost_against_competitor?.competitor_name }}</div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">他社落ち理由</label>
                        <div class="form-readonly">{{ project.lost_reason_label }}</div>
                    </div>
                    <div class="col-18">
                        <label class="form-label">失注理由</label>
                        <div class="form-readonly prewrap">{{ project.lost_cause }}</div>
                    </div>
                </div>
            </template>

            <!-- 自社運用、取得見送り -->
            <template v-else-if="Status.stageFailure().includes(selected_status)">
                <div class="row">
                    <div class="col-6">
                        <label class="form-label">失注日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.lost_date) }}</div>
                    </div>
                    <div class="col-30">
                        <label class="form-label">失注理由</label>
                        <div class="form-readonly prewrap">{{ project.lost_cause }}</div>
                    </div>
                </div>
            </template>

            <!-- 受注 -->
            <template v-else-if="selected_status === Status.STARTED">
                <div class="row mb-3">
                    <div class="col-6">
                        <label class="form-label">受注日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.ordered_date) }}</div>
                        <label class="form-label mt-3">顧客番号</label>
                        <div class="form-readonly">{{ project.client_number }}</div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">受注理由</label>
                        <div class="form-readonly">{{ project.ordered_reason_label }}</div>
                        <label class="form-label mt-3">契約金額</label>
                        <div class="form-readonly text-end">{{ $helper.number(project.contract_price) }} 円</div>
                    </div>
                    <div class="col-24">
                        <label class="form-label">受注理由詳細</label>
                        <div class="form-readonly prewrap">{{ project.ordered_reason_detail }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <label class="form-label">契約ステータス</label>
                        <div class="form-readonly">{{ project.contract_status_label }}</div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">契約書原案送付日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.contract_request_review_date) }}</div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">クラウドサイン送付日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.contract_request_sign_date) }}</div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">契約締結完了日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.contract_execute_date) }}</div>
                    </div>
                    <div class="col-6">
                        <label class="form-label">次回アプローチ日</label>
                        <div class="form-readonly">{{ $helper.ymd(project.next_approaching_date) }}</div>
                    </div>
                </div>
            </template>

            <!-- 追客なし 表示なし -->
            <template v-else>
            </template>
        </section>
    </section>
</template>

<script>
import Project from '@/models/entities/project';
import ProjectContact from '@/models/entities/project-contact';
import IsMajor from '@/models/enums/is-major';
import Status from '@/models/enums/status';
import Standard from '@/models/enums/standard';
import Rank from '@/models/enums/rank';
import LostReason from '@/models/enums/lost-reason';
import OrderedReason from '@/models/enums/ordered-reason';
import ContactType from '@/models/enums/contact-type';
import ContractStatus from '@/models/enums/contract-status';
import FormInput from '@/components/forms/FormInput';
import FormSelectObject from '@/components/forms/FormSelectObject';
import FormSelectObjectSearch from '@/components/forms/FormSelectObjectSearch';
import FormSelectSearch from '@/components/forms/FormSelectSearch';
import FormCheckbox from '@/components/forms/FormCheckbox';
import FormInputDate from '@/components/forms/FormInputDate';
import FormInputNumber from '@/components/forms/FormInputNumber';
import FormInputDatetime from '@/components/forms/FormInputDatetime';
import FormSelect from '@/components/forms/FormSelect';
import FormTextarea from '@/components/forms/FormTextarea';
import FormSelectSearchMultiple from '@/components/forms/FormSelectSearchMultiple';
import Probability from '@/models/entities/probability';
import DetailedRank from '@/models/entities/detailed-rank';
import Certainty from '@/models/entities/certainty';
import Competitor from '@/models/entities/competitor';

export default {
    name: 'ProjectHeader',
    components: {
        FormInput,
        FormSelectObject,
        FormSelectObjectSearch,
        FormSelectSearch,
        FormCheckbox,
        FormInputDate,
        FormInputNumber,
        FormInputDatetime,
        FormSelect,
        FormTextarea,
        FormSelectSearchMultiple
    },
    props: {
        project: {
            type: Object,
            default: new Project(),
        },
        options_certainty: {
            type: Object,
        },
        options_department: {
            type: Object,
        },
        options_user: {
            type: Object,
        },
        options_competitor: {
            type: Object,
        },
        // 案件情報の編集中フラグ
        is_editing_detail: {
            type: Boolean,
        },
        // 顧客情報の編集中フラグ
        is_editing_customer: {
            type: Boolean,
        },
    },
    emits: [
        'update',
        'updateOptionsProbability',
        'updateOptionsDetailedRank',
        'updateOptionsCertainty',
        'updateOptionsCompetitor',
        'changeIsEditHeader',
    ],
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage',
    ],
    data() {
        return {
            Status,
            Standard,
            Rank,
            ContactType,
            LostReason,
            OrderedReason,
            ContractStatus,
            IsMajor,

            edit_primary_user: new ProjectContact(),
            primary_user: new ProjectContact(),
            selected_status: null,

            edit_project: new Project(JSON.parse(JSON.stringify(this.project))),
            certainties: this.options_certainty.slice(),
            probabilities: [],
            detailed_ranks: [],
            competitors: [],

            is_edit: false,
        }
    },
    watch: {
        project(new_project) {
            this.edit_project = new Project(new_project);
            this.selected_status = new_project.status;
            this.getPrimaryUser(new_project);
        },
        options_certainty: {
            handler(new_options_certainty) {
                this.certainties = new_options_certainty.slice();
            },
            deep: true,
        },
    },
    created() {
        this.getPrimaryUser(this.project);
        this.selected_status = this.project.status;
    },
    mounted() {
        //初期表示時に、ステータスのactiveに横スクロール
        const target_active = this.$refs.scroll_container.querySelector('.active');
        if (target_active) {
            var adjust = 450; //調整値
            if (target_active.offsetLeft > adjust) {
                this.$refs.scroll_container.scrollLeft = target_active.offsetLeft - adjust;
            }
        }
    },
    methods: {
        getPrimaryUser(project) {
            if (project.contacts.length === 0) {
                return;
            }

            const primary_user = project.contacts.find(contact => contact.contact_type === this.ContactType.PRIMARY);
            this.edit_primary_user = new ProjectContact(primary_user);
            this.primary_user = new ProjectContact(primary_user);
        },
        selectStatus(status) {
            this.selected_status = parseInt(status.value, 10);
        },
        isSelected(status) {
            return this.selected_status === parseInt(status.value, 10);
        },
        // ステータス更新
        update() {
            if (
                this.project.status === parseInt(this.selected_status, 10)
                || this.selected_status === null
            ) {
                this.showErrorMessage('ステータスを選択してください');
                return;
            }

            this.startScreenLoading();

            this.$http.post(`/project/${this.project.project_id}/status`, {
                selected_status: this.selected_status,
            })
            .then(response => {
                this.$emit('update', response.data);
                this.showMessage('ステータスを更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        switchEditMode() {
            this.is_edit = true;
            this.changeIsEdit(true);
        },

        // 事業部が変更されたら取得し直し
        changeDepartment() {
            this.is_disabled = true;

            // 下記プロパティを全てリセット
            this.edit_project.probability = null; //優先度
            this.edit_project.detailed_rank = null; //サブ評価
            this.edit_project.certainty = null; //確実度
            this.edit_project.competitors = []; //競合セット
            this.edit_project.lost_competitors = []; //候補落ち競合セット

            // 選択肢切り替え
            this.fetchProbabilities();
            this.fetchDetailedRanks();
            this.fetchCertainties();
            this.fetchCompetitors();
        },

        // 優先度マスタ取得
        fetchProbabilities() {
            return new Promise(resolve => {
                this.probabilities.splice(0);

                this.$http.get('/master/probability', {
                    params: {
                        department: this.edit_project.department.department_id,
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let probability = new Probability(row);
                        this.probabilities.push(probability);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // サブ評価マスタ取得
        fetchDetailedRanks() {
            return new Promise(resolve => {
                this.detailed_ranks.splice(0);

                this.$http.get('/master/detailed-rank', {
                    params: {
                        department: this.edit_project.department.department_id,
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let detailed_rank = new DetailedRank(row);
                        this.detailed_ranks.push(detailed_rank);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // 確実度マスタ取得
        fetchCertainties() {
            return new Promise(resolve => {
                this.certainties.splice(0);

                this.$http.get('/master/certainty', {
                    params: {
                        department: this.edit_project.department.department_id,
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let certainty = new Certainty(row);
                        this.certainties.push(certainty);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // 競合マスタ取得
        fetchCompetitors() {
            return new Promise(resolve => {
                this.competitors.splice(0);

                this.$http.get('/master/competitor', {
                    params: {
                        department: this.edit_project.department.department_id,
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let competitor = new Competitor(row);
                        this.competitors.push(competitor);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },

        // 保存処理
        save() {
            this.startScreenLoading();

            // 客先担当者をセット（主担当者の情報のみを上書き * 必ず1行だけ存在しているはず）
            for (let i in this.edit_project.contacts) {
                if (this.edit_project.contacts[i].contact_type === this.ContactType.PRIMARY) {
                    this.edit_project.contacts[i].contact_name = this.edit_primary_user.contact_name;
                    this.edit_project.contacts[i].contact_name_kana = this.edit_primary_user.contact_name_kana;
                    this.edit_project.contacts[i].position = this.edit_primary_user.position;
                    this.edit_project.contacts[i].phone = this.edit_primary_user.phone;
                    this.edit_project.contacts[i].email = this.edit_primary_user.email;
                }
            }

            this.$http.put(`/project/${this.edit_project.project_id}`, this.edit_project)
            .then((response) => {
                this.is_edit = false;
                this.changeIsEdit(false);

                this.showMessage('案件情報を保存しました');

                // 事業部が変更されていたら optionの変更emit
                if (this.project.department.department_id !== this.edit_project.department.department_id) {
                    this.$emit('updateOptionsProbability', this.probabilities.slice());
                    this.$emit('updateOptionsDetailedRank', this.detailed_ranks.slice());
                    this.$emit('updateOptionsCertainty', this.certainties.slice());
                    this.$emit('updateOptionsCompetitor', this.competitors.slice());
                }

                this.$emit('update', response.data);
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },

        // 親に渡して案件情報と顧客情報の編集を制御
        changeIsEdit(bool) {
            this.$emit('changeIsEditHeader', bool);
        },
    },
}
</script>

<style scoped>
    .row { align-items: stretch; }
    .section div.row>* {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .status-bar li:hover {
        cursor: pointer;
    }
    .selected {
        background-color: var(--bs-gray-400);
    }

    .selected + li:before {
        background-color: var(--bs-gray-400);
    }
    .status-bar li.selected:not(.active):last-child:after {
        background-color: var(--bs-gray-400);
    }
</style>