<template>
    <Multiselect
        v-model="values"
        @select="select()"
        @deselect="select()"
        @clear="clear()"
        mode="tags"
        :close-on-select="false"
        :placeholder="placeholder"
        :options="selectOptions"
        :searchable="searchable"
        :required="required"
        :disabled="disabled"
        noResultsText="項目なし"
        class="multiselect-blue"
    />
</template>

<script>
import Multiselect from '@vueform/multiselect';

// npm
// https://www.npmjs.com/package/@vueform/multiselect#events
export default {
    name: 'FormSelectObjectSearchMultiple',
    components: {
        Multiselect,
    },
    data() {
        return {
            values: [],
            selectOptions: [],
        }
    },
    props: {
        modelValue: {
        },
        //SELECTで選択した結果を返す型
        model_type: {
            type: String,
            default: 'int' // int, float, string
        },
        options: {
            type: Array
        },
        option_value: {
            type: String,
            default: 'value'
        },
        option_label: {
            type: String,
            default: 'label'
        },
        required: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: null,
        },
        searchable: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'update:modelValue',
        'selected',
    ],
    mounted() {
        this.values = this.formatValues();
        this.selectOptions = this.formatOptions();
    },
    watch: {
        modelValue: function () {
            this.values = this.formatValues();
        },
        options: {
            deep: true,
            handler() {
                this.selectOptions = this.formatOptions();
            }
        }
    },
    methods: {
        formatValues() {
            if (!this.modelValue) {
                return [];
            }

            return this.modelValue.map((value) => {
                return value[this.option_value];
            });
        },
        formatOptions() {
            if (!this.options) {
                return [];
            }

            return this.options.map((option) => {
                return {
                    value: option[this.option_value],
                    label: option[this.option_label]
                };
            });
        },
        convert() {
            return this.values.map((value) => {
                return this.options.find((option) => {
                    return option[this.option_value] === value;
                });
            });
        },
        select() {
            this.$emit('update:modelValue', this.convert());
            this.$emit('selected', this.convert());
        },
        clear() {
            this.$emit('update:modelValue', []);
            this.$emit('selected', []);
        },
    }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect-blue {
    --ms-tag-bg: #33AACC;
    --ms-line-height: 29.8px;
    --ms-tag-font-size: 0.725rem;
    --ms-tag-line-height: 1rem;
    --ms-tag-py: 0;
    --ms-option-font-size: 0.9rem;
}
</style>