<template>

    <!-- フィードバック -->
    <!-- 「閲覧のみ」権限でフィードバックが一つもなければ非表示 -->
    <section v-if="$store.getters['auth/canEditProject'] || edit_project.files.length > 0" class="section">
        <h3 class="section-title">フィードバック</h3>
        <template v-if="project.feedbacks.length">
            <div class="p-3 mb-3">
                <div class="bg-light mb-3 p-3">
                    <p class="border-bottom">{{ project.feedbacks[0].user.user_name }} さんより ({{ $helper.ymd(project.feedbacks[0].feedback_datetime) }})</p>
                    <p class="prewrap">{{ project.feedbacks[0].feedback_detail }}</p>
                    <p v-if="$store.state.auth.user_id === project.feedbacks[0].user.user_id" class="text-end">
                        <button class="btn btn-link" @click="removeFeedbackReady(project.feedbacks[0])"><i class="bi bi-trash"></i> 削除</button>
                    </p>
                </div>
            </div>
            <template v-if="project.feedbacks.length > 1">
                <div class="accordion">
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button @click="is_open = !is_open" :class="{'collapsed': is_open === false}" class="accordion-button p-2" type="button">他{{ project.feedbacks.length - 1 }}件</button>
                        </h2>
                        <div :class="{'show': is_open === true}" class="accordion-collapse collapse">
                            <template v-for="feedback, index in project.feedbacks" :key="feedback.feedback_id">
                                <div v-if="index > 0" class="accordion-body border-bottom p-4">
                                    <div class="bg-light p-4">
                                        <p class="border-bottom">{{ feedback.user.user_name }} さんより ({{ $helper.ymd(feedback.feedback_datetime) }})</p>
                                        <p class="prewrap">{{ feedback.feedback_detail }}</p>
                                        <p v-if="$store.state.auth.user_id === feedback.user.user_id" class="text-end">
                                            <button class="btn btn-link" @click="removeFeedbackReady(feedback)"><i class="bi bi-trash"></i> 削除</button>
                                        </p>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
        </template>
        <div v-if="$store.getters['auth/canEditProject']" class="mt-3">
            <div class="form-label">フィードバックを送信</div>
            <form-textarea v-model="feedback_to_create.feedback_detail"></form-textarea>
            <div class="text-end mt-2">
                <button @click="save()" class="btn btn-info">送信</button>
            </div>
        </div>
    </section>

    <confirm-dialog ref="removeFeedback" @ok="removeFeedback">
        <p>本当に削除してよろしいですか？</p>
    </confirm-dialog>
</template>

<script>
import Project from '@/models/entities/project';
import FormTextarea from '@/components/forms/FormTextarea';
import Feedback from '@/models/entities/feedback';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';

export default {
    name: 'ProjectFeedback',
    components: {
        FormTextarea,
        ConfirmDialog,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    props: {
        project: {
            type: Object,
            default: new Project(),
        }
    },
    emits: [
    ],
    data() {
        return {
            feedback_to_create: new Feedback(),
            edit_project: new Project(JSON.parse(JSON.stringify(this.project))),
            feedback_to_remove: null,
            // アコーディオン開閉フラグ
            is_open: false,
        }
    },
    // 親コンポーネントprojectの変更を検知
    watch: {
        project(new_project) {
            this.edit_project = new_project;
        },
    },
    methods: {
        save() {
            // 空白のときは送信させない
            if (this.$helper.isBlank(this.feedback_to_create.feedback_detail)) {
                return;
            }

            this.startScreenLoading();

            this.$http.post(`/project/${this.edit_project.project_id}/feedback`, this.feedback_to_create)
            .then(response => {
                this.edit_project.feedbacks.push(new Feedback(response.data));
                this.$emit('save', this.edit_project);
                // 入力欄リセット
                this.feedback_to_create = new Feedback();
                this.showMessage('フィードバックを追加しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        removeFeedbackReady(feedback)
        {
            this.feedback_to_remove = feedback;
            this.$refs.removeFeedback.show();
        },
        removeFeedback()
        {
            this.startScreenLoading();

            this.$http.delete(`/project/${this.edit_project.project_id}/feedback/${this.feedback_to_remove.feedback_id}`)
            .then(() => {
                this.edit_project.feedbacks = this.edit_project.feedbacks.filter(row => row.feedback_id !== this.feedback_to_remove.feedback_id);
                this.$emit('save', this.edit_project);
                this.feedback_to_remove = null;
                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        }
    },
}
</script>