<template>

    <!-- 重複疑い -->
    <section class="section">
        <header class="section-title">
            <h3><i class="bi bi-exclamation-triangle text-danger"></i> 重複疑い</h3>
        </header>
        <template v-for="duplicate in duplicates" :key="duplicate.duplicate_id">
            <div class="border-bottom">
                <div class="p-4">
                    <h4>
                        <!-- project_idが異なるやつが重複疑いの案件 -->
                        <!-- 同じpageの場合router-linkではパスパラメータが変わっただけでは再描画されないのでaタグのhrefで実装 -->
                        <a :href="'/project/' + duplicateProject(duplicate).project_id">
                            {{ `${duplicateProject(duplicate).customer.company_name}（${$helper.ymd(duplicateProject(duplicate).created_datetime)} 登録）` }}
                        </a>
                    </h4>
                    <template v-if="$store.getters['auth/canEditProject']">
                        <div class="mt-3">
                            <button @click="readyMerge(duplicate, 1)" class="btn btn-outline-danger"><i class="bi bi-box-arrow-in-down-left"></i> この案件に上記案件を統合</button>
                        </div>
                        <div class="mt-3">
                            <button @click="readyMerge(duplicate, 2)" class="btn btn-outline-danger"><i class="bi bi-box-arrow-in-up-right"></i> 上記案件にこの案件を統合</button>
                        </div>
                        <div class="mt-3">
                            <button @click="readyMerge(duplicate, 3)" class="btn btn-outline-secondary"><i class="bi bi-check-circle"></i> 重複ではない</button>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </section>

    <confirm-dialog ref="confirmMerge" @ok="merge">
        <p>{{ `${duplicateProject(merge_duplicate).customer.company_name}（${$helper.ymd(duplicateProject(merge_duplicate).created_datetime)} 登録）` }}</p>
        <p v-if="merge_type === 1">この案件に上記案件を統合してよろしいですか？</p>
        <p v-if="merge_type === 2">上記案件にこの案件を統合してよろしいですか？</p>
        <p v-if="merge_type === 3">この案件は上記案件と重複ではないですか？</p>
    </confirm-dialog>

</template>

<script>
import Project from '@/models/entities/project';
import ConfirmDialog from '@/components/tools/ConfirmDialog';
import DuplicateStatus from '@/models/enums/duplicate-status';

export default {
    name: 'ProjectDuplicate',
    components: {
        ConfirmDialog,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    props: {
        project: {
            type: Object,
            default: new Project(),
        },
        duplicates: {
            type: Array,
        }
    },
    emits: [
        'update',
        'remove_duplicate'
    ],
    data() {
        return {
            DuplicateStatus,

            merge_duplicate: null,
            merge_type: null,
        }
    },
    methods: {
        duplicateProject(duplicate) {
            if (duplicate.first_project_id === this.project.project_id) {
                return duplicate.second_project;
            } else {
                return duplicate.first_project;
            }
        },
        readyMerge(merge_duplicate, merge_type) {
            this.merge_duplicate = merge_duplicate;
            this.merge_type = merge_type;
            this.$refs.confirmMerge.show();
        },
        // 統合処理
        merge() {
            if (this.$helper.isBlank(this.merge_type)) {
                return;
            }

            this.startScreenLoading();

            // 大事!!!!!!!!
            // パスパラメータのprojectが統合するproject
            // 統合されるprojectは第二引数のparamに詰める
            // 統合されるprojectをnullにすると重複ではない扱い

            // 統合する
            let merge_project_id = null;
            // 統合される
            let merged_project_id = null;

            // この案件に統合
            if (this.merge_type === 1) {
                merge_project_id = this.project.project_id;
                merged_project_id = this.duplicateProject(this.merge_duplicate).project_id;
            }
            // この案件を統合
            if (this.merge_type === 2) {
                merge_project_id = this.duplicateProject(this.merge_duplicate).project_id;
                merged_project_id = this.project.project_id;
            }
            // 重複ではない
            if (this.merge_type === 3) {
                merge_project_id = this.project.project_id;
            }

            this.$http.post(`/project/${merge_project_id}/duplicate/${this.merge_duplicate.duplicate_id}`, {
                merged_project_id,
            })
            .then(response => {
                // 削除処理
                const remove_index = this.duplicates.findIndex(row => row.duplicate_id === this.merge_duplicate.duplicate_id);
                this.$emit('remove_duplicate', remove_index);

                // 案件更新 projectが返ってくる
                this.$emit('update', response.data);

                if (this.merge_type === 3) {
                    this.showMessage('重複ではなくしました');
                } else {
                    this.showMessage('統合しました');
                }
                this.merge_duplicate = null;
                this.merge_type = null;
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
    },
}
</script>