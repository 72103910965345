<template>
    <div class="row" :class="{[optional_class]: true}">
        <div class="col-16 pe-0 form-date">
            <input
                type="date"
                max="9999-12-31"
                v-model="inputDate"
                :required="isRequired"
                :disabled="disabled"
                :date_placeholder="date_placeholder"
                class="form-control"
            >
        </div>
        <div class="col-10 p-0 form-hour">
            <div class="input-group">
                <input
                    type="number"
                    min="0"
                    max="23"
                    v-model="inputHour"
                    :required="isRequired"
                    :disabled="disabled"
                    :hour_placeholder="hour_placeholder"
                    class="form-control"
                >
                <span class="input-group-text">時</span>
            </div>
        </div>
        <div class="col-10 ps-0 form-time">
            <div class="input-group">
                <input
                    type="number"
                    min="0"
                    max="59"
                    class="form-control"
                    v-model="inputMinute"
                    :required="isRequired"
                    :disabled="disabled"
                    :minute_placeholder="minute_placeholder"
                >
                <span class="input-group-text">分</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FormInputDatetime',
    props: {
        modelValue: {
            type: String
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        date_placeholder: {
            type: String,
            default: ""
        },
        hour_placeholder: {
            type: String,
            default: ""
        },
        minute_placeholder: {
            type: String,
            default: ""
        },
        optional_class: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            inputDate: null,
            inputHour: null,
            inputMinute: null,
            returningDatetime: {
                date: null,
                hour: null,
                minute: null,
            }
        }
    },
    emits: [
        'update:modelValue'
    ],
    mounted() {
        this.setModelValue();
    },
    watch: {
        modelValue() {
            this.setModelValue();
        },
        inputDate() {
            this.generateReturnedDate();
            this.emitModelValue();
        },
        inputHour() {
            this.generateReturnedHour();
            this.emitModelValue();
        },
        inputMinute() {
            this.generateReturnedMinute();
            this.emitModelValue();
        }
    },
    computed: {
        isRequired() {
            if (this.required) {
                return true;
            }

            if (this.inputDate !== null && this.inputDate !== "") {
                return true
            }

            if (this.isFilledHour || this.isFilledMinute) {
                return true;
            }

            return false;
        },
        isFilledHour() {
            if (this.inputHour === null || this.inputHour ==='') {
                return false;
            }

            return true;
        },
        isFilledMinute() {
            if (this.inputMinute === null || this.inputMinute ==='') {
                return false;
            }

            return true;
        }
    },
    methods: {
        setModelValue() {
            if (this.$helper.isBlank(this.modelValue)) {
                return;
            }

            let modelDatetime = new Date(this.modelValue);

            // datetime以外の値がきた場合
            if (isNaN(modelDatetime.getTime())) {
                return;
            }

            // 初期表示ではない場合
            if (this.returningDatetime.date) {
                return;
            }

            // 初期表示
            this.inputDate = `${modelDatetime.getFullYear()}-${("0" + (modelDatetime.getMonth() + 1)).slice(-2)}-${("0" + modelDatetime.getDate()).slice(-2)}`;
            this.inputHour = ("0" + modelDatetime.getHours()).slice(-2);
            this.inputMinute = ("0" + modelDatetime.getMinutes()).slice(-2);
        },
        generateReturnedDate() {
            if (this.inputDate === null || this.inputDate === "") {
                return null;
            }

            return this.inputDate;
        },
        generateReturnedHour() {
            if (!this.isFilledHour) {
                return null;
            }

            return ("0" + this.inputHour).slice(-2);
        },
        generateReturnedMinute() {
            if (!this.isFilledMinute) {
                return null;
            }

            return ("0" + this.inputMinute).slice(-2);
        },
        generateDatetime(date, hour, minute) {
            return `${date} ${hour}:${minute}:00`
        },
        emitModelValue() {
            // 日付がない時はnull扱い
            if (!this.generateReturnedDate()) {
                this.$emit('update:modelValue', null);
                return;
            }

            this.returningDatetime.date = this.generateReturnedDate();
            this.returningDatetime.hour = this.generateReturnedHour() ?? '00';
            this.returningDatetime.minute = this.generateReturnedMinute() ?? '00';

            this.$emit('update:modelValue', this.generateDatetime(this.returningDatetime.date, this.returningDatetime.hour, this.returningDatetime.minute));
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .form-date input {border-top-right-radius: 0; border-bottom-right-radius: 0;}
    .form-hour input {border-top-left-radius: 0; border-bottom-left-radius: 0; border-left: none;}
    .form-hour span {border-top-right-radius: 0; border-bottom-right-radius: 0;}
    .form-time input {border-top-left-radius: 0; border-bottom-left-radius: 0; border-left: none;}
</style>
