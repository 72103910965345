<template>

    <!-- ファイル -->
    <!-- 「閲覧のみ」権限でファイルが一つもなければ非表示 -->
    <section v-if="$store.getters['auth/canEditProject'] || edit_project.files.length > 0" class="section pb-5">
        <div class="section-title">
            <h2>ファイル</h2>
        </div>

        <div class="row mb-3">
            <!-- ファイル表示 -->
            <template v-for="file in edit_project.files" :key="file.project_file_id">
                <div class="col-9 position-relative figure-box">
                    <button @click="removeReady(file)" class="btn btn-sm btn-light position-absolute top-0 end-0"><i class="bi bi-x"></i></button>
                    <figure class="file-figure">
                        <img :src="viewFile(file)" :alt="file.file_name" style="width:100%;">
                    </figure>
                    <div class="form-readonly"><a :href="displayFile(file)" target="_blank" rel="noopener noreferrer">{{ file.file_name }}</a></div>
                </div>
            </template>
            <!-- ファイルアップロード -->
            <div v-if="$store.getters['auth/canEditProject']" class="col-9 upload-button-box">
                <label class="bg-light d-flex justify-content-center align-items-center" role="button" style="height:100%;">
                    <input type="file" ref="file" @change="uploadFiles" multiple="multiple" style="display:none;">
                    <div class="upload-button"><i class="bi bi-upload"></i> アップロード</div>
                </label>
            </div>
        </div>
    </section>

    <confirm-dialog ref="confirmRemove" @ok="remove">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import ProjectFile from '@/models/entities/project-file';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';
import Project from '@/models/entities/project';

export default {
    name: 'ProjectFile',
    components: {
        ConfirmDialog,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    props: {
        project: {
            type: Object,
            default: new Project(),
        }
    },
    emits: [
    ],
    data() {
        return {
            edit_project: new Project(JSON.parse(JSON.stringify(this.project))),

            file_to_remove: null,
        }
    },
    methods: {
        viewFile(file) {
            return `${process.env.VUE_APP_API_URI}/project/${this.edit_project.project_id}/file/${file.project_file_id}?access_token=${this.$store.state.auth.access_token}&display=0`;
        },
        displayFile(file) {
            return `${process.env.VUE_APP_API_URI}/project/${this.edit_project.project_id}/file/${file.project_file_id}?access_token=${this.$store.state.auth.access_token}&display=1`;
        },
        // ファイルアップロード
        uploadFiles() {
            // アップロードされたファイルを格納
            if (this.$refs.file.files.length === 0) {
                return;
            }

            this.startScreenLoading();

            const formData = new FormData()
            for (let row of this.$refs.file.files){
                formData.append('file[]', row);
            }

            let headers = {
                'content-type': 'multipart/form-data'
            };
            // 詰めたファイルをPOST
            this.$http.post(`/project/${this.edit_project.project_id}/file`, formData, {headers})
            .then(response => {
                for (let row of response.data) {
                    this.edit_project.files.push(new ProjectFile(row));
                }
                this.showMessage('ファイルをアップロードしました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },

        removeReady(file) {
            this.file_to_remove = file;
            this.$refs.confirmRemove.show();
        },
        // ファイル削除
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/project/${this.edit_project.project_id}/file/${this.file_to_remove.project_file_id}`)
            .then(() => {
                const remove_file_id = this.edit_project.files.findIndex(file => file.project_file_id === this.file_to_remove.project_file_id);
                this.edit_project.files.splice(remove_file_id, 1);
                this.showMessage('ファイルを削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
    },
}
</script>

<style scoped>
    .figure-box {display: flex; flex-direction: column; justify-content: space-between; margin-top: .3rem; }
    .file-figure {max-height: 100px; margin-bottom: .3rem;}
    .file-figure img {object-fit: contain; max-height: 100px;}
    .upload-button-box {margin-top: .3rem;}
    .upload-button {min-height: 150px; display: flex; align-items: center; justify-content: center; padding: .5rem;}
    .upload-button i {margin-right: .5rem;}
</style>