<template>

    <!-- 変更履歴 -->
    <section class="section">
        <h3 class="section-title">変更履歴</h3>
        <ul class="list-group">
            <template v-for="history in project.histories" :key="history.project_history_id">
                <li class="list-group-item p-3 d-flex">
                    <div class="col-12 fs-7">{{ $helper.ymdhi(history.edit_datetime) }}</div>
                    <div class="col-24">
                        <div v-if="history.before_status !== history.after_status" class="fs-7">
                            ステータス :
                            <strong>{{ history.before_status_label ?? '（未設定）' }}</strong>
                            →
                            <strong class="text-primary">{{ history.after_status_label }}</strong>
                        </div>

                        <div v-if="history.before_follow_user?.user_id !== history.after_follow_user?.user_id">
                            追客担当 :
                            <strong v-if="history.before_follow_user">{{ history.before_follow_user.user_name }}</strong>
                            <strong v-else>（未設定）</strong>
                            →
                            <strong v-if="history.after_follow_user" class="text-primary">{{ history.after_follow_user.user_name }}</strong>
                            <strong v-else class="text-primary">（未設定）</strong>
                        </div>
                    </div>
                </li>
            </template>
        </ul>
    </section>

</template>

<script>

export default {
    name: 'ProjectChangeHistory',
    props: {
        project: {
            type: Object,
        }
    },
    data() {
        return {
        }
    },
}
</script>
<style scoped>
    .fs-7 {font-size: .85rem;}
</style>