import Enum from './enum'

/**
 * 問題あり・問題なし
 */
class IsProblem extends Enum {
    static YES = 1; // 問題あり
    static NO = 0; // 問題なし

    static values() {
        return {
            [this.YES]: '問題あり',
            [this.NO]: '問題なし',
        }
    }
}

export default IsProblem;
