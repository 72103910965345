<template>
    <div class="input-group" :class="{[optional_class]: true}">
        <input
            type="number"
            min="0"
            max="23"
            class="form-control"
            v-model="hour"
            :required="required || (minute !== '' && minute !== null)"
            :disabled="disabled"
            @input="emitModelValue()"
        >
        <span class="input-group-text">時</span>
        <input
            type="number"
            min="0"
            max="59"
            class="form-control"
            v-model="minute"
            :required="required || (hour !== '' && hour !== null)"
            :disabled="disabled"
            @input="emitModelValue()"
        >
        <span class="input-group-text">分</span>
    </div>
</template>

<script>
export default {
    name: 'FormInputTime',
    props: {
        modelValue: {
            type: String
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        optional_class: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            hour: null,
            minute: null,
        }
    },
    emits: [
        'update:modelValue'
    ],
    mounted() {
        this.setValue();
    },
    watch: {
        modelValue() {
            this.setValue();
        },
    },
    methods: {
        setValue() {
            if (this.modelValue == null) { // == null : null or undefined であれば
                return;
            }

            let part = this.modelValue.split(':');
            if (part.length < 2) {
                return;
            }

            this.hour = parseInt(part[0]);
            this.minute = parseInt(part[1]);
        },
        emitModelValue() {
            if (this.hour === "" || this.hour === null) {
                return;
            }
            if (this.minute === "" || this.minute === null) {
                return;
            }

            let value = ('0' + this.hour).slice(-2)
                    + ":"
                    + ('0' + this.minute).slice(-2)
                    + ":00";

            this.$emit('update:modelValue', value);
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .form-date input {border-top-right-radius: 0; border-bottom-right-radius: 0;}
    .form-hour input {border-top-left-radius: 0; border-bottom-left-radius: 0; border-left: none;}
    .form-hour span {border-top-right-radius: 0; border-bottom-right-radius: 0;}
    .form-time input {border-top-left-radius: 0; border-bottom-left-radius: 0; border-left: none;}
</style>
